<template>
  <v-app>
    <div id="app">
      <div style="display: none; margin-bottom: 30px" id="top_header">
        <div >
          <v-app-bar id="bar_header" color="#E2E1F1">
            <v-toolbar-title>
              <v-img class="shrink mt-1 hidden-sm-and-down" contain src="./images/top_logo.png" width="200px" />
            </v-toolbar-title>
            <b><h2>&nbsp;&nbsp;&nbsp;<font style="color: #828282"><span id="topNaviText"></span></font></h2></b>
            <v-spacer>
              <div style="width:1000px">
                <ul>
                  <li>
                    <router-link to="/barcode">BarCode</router-link>
                  </li>
                  <li>
                    <router-link to="/order-shipping">Shipping List</router-link>
                  </li>
                  <li>
                    <router-link to="/order-shipping/manifest">Manifest</router-link>
                  </li>
                  <li>
                    <router-link to="/order-shipping/T86">T86</router-link>
                  </li>
                  <li>
                    <router-link to="/order-shipping/packageList">Package List</router-link>
                  </li>
                  <li>
                    <router-link to="/BarcodeViewUseOnly">BarcodeViewUseOnly</router-link>
                  </li>
                </ul>
              </div>
            </v-spacer>
            <span>Welcome Partner<b>&nbsp;<span id="companyName"></span>!</b>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            &nbsp;&nbsp;
            <v-btn icon @click="logout">
              <v-img class="shrink mt-1 hidden-sm-and-down ma-1 pd-1" contain src="./images/header3.png" width="40px" />
            </v-btn>
          </v-app-bar>
        </div>
      </div>
      <router-view />
      <notifications group="notifyApp" position="bottom center" align="right" />
    </div>
  </v-app>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}

li {
  cursor: pointer;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  border-bottom: 2px solid transparent;
}
.router-link-exact-active {
  font-size: 22px !important;
  color: blue !important;
}

li:hover {
  border-bottom: 3px solid #333;
}
.v-application a {
  text-decoration-line: none;
  font-weight: 700;
  font-size: 20px;
}
</style>

<script>
// eslint-disable-next-line no-unused-vars
import axios from "axios";
import $ from "jquery";
import VueCookies from "vue-cookies";


export default {
  data: () => ({
    dialog: true,
  }),
  methods: {
    handleClick(item) {
      // 메뉴 클릭 이벤트 처리
      console.log(`Clicked ${item.route}`);
    },
    setAlarm() {
      this.$alert("Alarm Action.", "", "info").then(() => {
        this.$refs.password.focus();
      });
    },
    logout() {
      this.$session.set("loginIdPartner", "");
      this.$session.set("authorizationPartner", "");
      VueCookies.set("companyNamePartner", "");
      this.$router.push("/");
    },
    goHome() {
      this.$router.push("/home");
    },
    getToken() {
      let formData = new FormData();
      formData.append("grant_type", "password");
      formData.append("username", process.env.VUE_APP_AUTH_USERNAME)
      formData.append("password", process.env.VUE_APP_AUTH_PASSWORD);  

      // eslint-disable-next-line no-unused-vars
      axios
        .post(process.env.VUE_APP_AUTH_API_URL, formData, {
          headers: {
            Authorization: process.env.VUE_APP_AUTH_TOKEN,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset = utf-8",
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.authorization = response.data.token_type + " " + response.data.access_token;
          this.$session.set("authorizationPartner", this.authorization);
        })
        .catch((err) => {
          if (
            err.response.data.error_description === "Bad credentials" ||
            err.response.data.error === "invalid_grant"
          ) {
            this.$alert("사용자 아이디 또는 비밀번호를 확인하시기 바랍니다.", "", "info").then(() => {});
          } else if (err.response.data.error === "unauthorized") {
            this.$alert(err.response.data.error_description, "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (AX)") {
            this.$alert("사용자 계정의 유효 기간이 만료 되었습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (AL)") {
            this.$alert("사용자 계정이 잠겨 있습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (CX)") {
            this.$alert("자격 증명 유효 기간이 만료되었습니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (NA)") {
            this.$alert("유효하지 않은 사용자입니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description === "Bad credentials (UC)") {
            this.$alert("접근이 허용되지 않은 사용자입니다.", "", "info").then(() => {});
          } else if (err.response.data.error_description !== undefined) {
            this.$alert(err.response.data.error_description, "", "info").then(() => {});
          }
        });
    }
  },
  mounted() {
    $("#companyName").text(VueCookies.get("companyNamePartner"));
    this.getToken();

    //1시간마다 토큰 갱신
    var vm = this;
    setInterval(function() {
      vm.getToken();
      }
    , 3600000);
  },
};
</script>
