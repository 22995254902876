import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueCookies from "vue-cookies"
import Notifications from 'vue-notification'
// eslint-disable-next-line no-unused-vars
import VueSession from 'vue-session'
// eslint-disable-next-line no-unused-vars
import VueAlertify from 'vue-alertify';
import VueSimpleAlert from 'vue-simple-alert'
// eslint-disable-next-line no-unused-vars
import JsBarcode from 'jsbarcode'
import Print from 'vue-print-nb'

var sessionOptions = {
  persist: true
}
Vue.use(Print)
Vue.use(VueSession, sessionOptions)
Vue.use(Notifications)
Vue.use(VueCookies)
Vue.use(VueSimpleAlert)
Vue.use(VueAlertify, {
  // dialogs defaults
  autoReset: true,
  basic: false,
  closable: true,
  closableByDimmer: false,
  frameless: false,
  maintainFocus: true, // <== global default not per instance, applies to all dialogs
  maximizable: true,
  modal: true,
  movable: true,
  moveBounded: false,
  overflow: true,
  padding: true,
  pinnable: true,
  pinned: true,
  preventBodyShift: false, // <== global default not per instance, applies to all dialogs
  resizable: true,
  startMaximized: false,
  transition: 'pulse',
 
  // notifier defaults
  notifier: {
    // auto-dismiss wait time (in seconds)
    delay: 5,
    // default position
    position: 'top-right',
    // adds a close button to notifier messages
    closeButton: false,
  },
 
  // language resources
  glossary: {
    // dialogs default title
    title: 'AlertifyJS',
    // ok button text
    ok: 'OK',
    // cancel button text
    cancel: 'Cancel',
  },
 
  // theme settings
  theme: {
    // class name attached to prompt dialog input textbox.
    input: 'ajs-input',
    // class name attached to ok button
    ok: 'ajs-ok',
    // class name attached to cancel button
    cancel: 'ajs-cancel',
  },
});

Vue.$cookies.config("30d") // expire 1일 (global 설정)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
