import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
    },
    {
        path: "/forgetPasswordEnd",
        name: "forgetPasswordEnd",
        component: () => import(/* webpackChunkName: "about" */ "../views/ForgetPasswordEndView.vue"),
    },
    {
        path: "/createAccount",
        name: "createAccount",
        component: () => import(/* webpackChunkName: "about" */ "../views/CreateAccountView.vue"),
    },
    {
        path: "/barcode",
        name: "barcode",
        component: () => import(/* webpackChunkName: "about" */ "../views/BarcodeView.vue"),
    },
    {
        path: "/BarcodeViewUseOnly",
        name: "BarcodeViewUseOnly",
        component: () => import(/* webpackChunkName: "about" */ "../views/BarcodeViewUseOnly.vue"),
    },
    {
        path: "/order-shipping",
        name: "orderShipping",
        component: () => import(/* webpackChunkName: "about" */ "../views/OrderShippingView.vue"),
    },
    {
        path: "/order-shipping/manifest",
        name: "orderShippingManifest",
        component: () => import(/* webpackChunkName: "about" */ "../views/OrderShippingManifestView.vue"),
    },
    {
        path: "/order-shipping/packageList",
        name: "orderShippingPackageList",
        component: () => import(/* webpackChunkName: "about" */ "../views/OrderShippingPackageList.vue"),
    },
    {
        path: "/order-shipping/T86",
        name: "orderShippingT86",
        component: () => import(/* webpackChunkName: "about" */ "../views/OrderShippingT86View.vue"),
    },
    {
        path: "/base_64",
        name: "base_64",
        component: () => import(/* webpackChunkName: "about" */ "../views/base_64.vue"),
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
